/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import ListItem from "./ListItem";

/**
 * Check if this the text of the given <li> elements is an ingredient list for a recipe.
 * @param {Array.<{props: {children: String|any}}>}children
 * @returns boolean
 */
function checkContainsIngredients(children) {
  return children.map(c => c.props.children)
    // find all lines that are definitely ingredients
    // format: approximately (number + quantity + something else) as regex
    // wherein the number can be something like 1, 1/2, 140 or ½
    .filter(it => {
      if (it instanceof String)
        return it.match(/^(\p{N}|\d|(\d\/\d)){1,4}\s?(g|cups|cup|kg|tsp|tbsp)\s?.*$/gu);
      else return false;
    })
    .length >= 2;
}

/**
 * Replace all fractions (e.g. 1/2) by their unicode equivalent (e.g. ½)
 * @param {String} ingredientLine
 * @return String
 */
function fractionsToUnicodeEquivalent(ingredientLine) {
  return ingredientLine.replace(/^(\d\/\d)/, (fraction) => {
    switch (fraction) {
      case "1/2":
        return "½";
      case "1/3":
        return "⅓";
      case "2/3":
        return "⅔";
      case "1/4":
        return "¼";
      case "3/4":
        return "¾";
      case "1/5":
        return "⅕";
      case "2/5":
        return "⅖";
      case "3/5":
        return "⅗";
      case "4/5":
        return "⅘";
      default:
        return fraction;
    }
  });
}

const IngredientList = ({ children }) => {
  // return simple <ul> if this is not an IngredientList
  if (!checkContainsIngredients(children)) {
    return <Themed.ul>{children}</Themed.ul>;
  }

  return (
    <Themed.ul>
      {children.map((c, idx) =>
        <Themed.li key={`ingredient${idx}`}>
          <ListItem>
            {fractionsToUnicodeEquivalent(c.props.children)}
          </ListItem>
        </Themed.li>
      )}
    </Themed.ul>
  );
};

export default IngredientList;
