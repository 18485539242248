/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import { MDXProvider } from "@mdx-js/react";

import PageLayout from "../components/PageLayout";
import BackLink from "../components/BackLink";
import ThemedParagraph from "./ThemedParagraph";
import ListItem from "./ListItem";
import IngredientList from "./IngredientList";
import useSiteMetadata from "../hooks/use-sitemetadata";
import PageLink from "./PageLink";

const components = {
  h1: Themed.h1,
  h2: Themed.h2,
  h3: Themed.h3,
  h4: Themed.h4,
  h5: Themed.h5,
  table: Themed.table,
  tr: Themed.tr,
  th: Themed.th,
  p: Themed.p,
  ThemedParagraph,
  ListItem,
  ul: IngredientList,
  a: PageLink
};

const MarkdownLayout = ({ children }) => {
  const { basePath } = useSiteMetadata();
  return (
    <MDXProvider components={components}>
      <PageLayout>
        <BackLink path={basePath}>Back to recipes</BackLink>
        <div sx={{ variant: "markdownWrappers.recipes" }}>{children}</div>
      </PageLayout>
    </MDXProvider>
  );
};

export default MarkdownLayout;
